// material-ui
import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

// styles
const LoaderWrapper = styled('div')({
    position: 'fixed',
    top: '50%',
    left: '50%',
    zIndex: 1301,
    width: '100%'
});

// ==============================|| LOADER ||============================== //

const Loader = () => (
    <LoaderWrapper>
        <CircularProgress color="secondary" />
    </LoaderWrapper>
);

export default Loader;
