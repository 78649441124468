import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// frontpage
const Dashboard = Loadable(lazy(() => import('views/application/dashboard')));

// calculations
const CalculationList = Loadable(lazy(() => import('views/application/user/calculations/CalculationList')));
const CalculationResult = Loadable(lazy(() => import('views/application/user/calculations/CalculationResult')));
const BorderCrosserCalculationForm = Loadable(
    lazy(() => import('views/application/user/calculations/border_crosser/CalculationForm/form'))
);
const WeeklyResidentCalculationForm = Loadable(lazy(() => import('views/application/user/calculations/weekly_resident/CalculationForm')));
const ResidentCalculationForm = Loadable(lazy(() => import('views/application/user/calculations/resident/CalculationForm')));

// health insurances
const ListHealthInsurance = Loadable(lazy(() => import('views/application/user/healthInsurances/ListHealthInsurance')));
const CreateHealthInsurance = Loadable(lazy(() => import('views/application/user/healthInsurances/CreateHealthInsurance')));
const EditHealthInsurance = Loadable(lazy(() => import('views/application/user/healthInsurances/EditHealthInsurance')));
const EditHealthInsuranceBoard = Loadable(lazy(() => import('views/application/user/healthInsurances/EditHealthInsurance/Board')));
const EditHealthInsuranceTariffs = Loadable(lazy(() => import('views/application/user/healthInsurances/EditHealthInsurance/Backlogs')));

// user
const RequestForSocialContributions = Loadable(lazy(() => import('views/application/user/calculations/social_security_contributions')));

// account
const AccountSettings = Loadable(lazy(() => import('views/application/user/account/Settings')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/user/calculations',
            element: <CalculationList />
        },
        {
            path: '/user/calculations/create/border-crosser',
            element: <BorderCrosserCalculationForm borderCrosser={{}} spouse={{}} />
        },
        {
            path: '/user/calculations/result/border-crosser',
            element: <CalculationResult />
        },
        {
            path: '/user/calculations/create/weekly-resident',
            element: <WeeklyResidentCalculationForm />
        },
        {
            path: '/user/calculations/result/weekly-resident',
            element: <CalculationResult />
        },
        {
            path: '/user/calculations/create/resident',
            element: <ResidentCalculationForm />
        },
        {
            path: '/user/calculations/result/resident',
            element: <CalculationResult />
        },
        {
            path: '/user/calculations/request-for-social-contributions',
            element: <RequestForSocialContributions />
        },
        {
            path: '/user/healthInsurances',
            element: <ListHealthInsurance />
        },
        {
            path: '/user/healthInsurances/create',
            element: <CreateHealthInsurance />
        },
        {
            path: '/user/healthInsurances/edit/:healthInsuranceId',
            element: <EditHealthInsurance />,
            children: [
                {
                    path: '',
                    element: <EditHealthInsuranceBoard />
                },
                {
                    path: 'tariffs',
                    element: <EditHealthInsuranceTariffs />
                }
            ]
        },
        {
            path: '/user/account',
            element: <AccountSettings />
        }
    ]
};

export default MainRoutes;
