import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import jwtDecode from 'jwt-decode';

// reducer - state management
import { ACCOUNT_UPDATE, LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axiosInstance';

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
    };

    const accountInit = async () => {
        try {
            const serviceToken = window.localStorage.getItem('serviceToken');
            if (serviceToken && verifyToken(serviceToken)) {
                setSession(serviceToken);
                const response = await axios.get('/accounts');
                dispatch({
                    type: LOGIN,
                    payload: {
                        user: response.data
                    }
                });
            } else {
                logout();
            }
        } catch (err) {
            console.error(err);
            logout();
        }
    };

    useEffect(() => {
        accountInit();
        // eslint-disable-next-line
    }, []);

    const login = async (email, password) => {
        const response = await axios.post('/auth/login', { email, password });
        const serviceToken = response.headers.authorization;
        setSession(serviceToken);
        // initialize the account to populate user data on login
        accountInit();
    };

    const confirmUser = async (verificationToken) => {
        const response = await axios.get('/users/register/confirm', {
            params: {
                token: verificationToken
            }
        });
        if (response.status !== 204) {
            // there has been an error
            return Promise.reject(new Error('request failed'));
        }
        // only set the axios auth header as we only login the user after he set his password
        axios.defaults.headers.common.Authorization = `Bearer ${response.headers.authorization}`;
        return response;
    };

    const updateCustomUserData = async (payload) => {
        const body = {
            email: payload.email.trim(),
            phone: payload.phone.trim()
        };
        if (payload.logoUrl) {
            body.logoUrl = payload.logoUrl.trim();
        }
        const response = await axios.put('/accounts/customData', body);
        if (response.status !== 204) {
            // there has been an error
            return Promise.reject(new Error('request failed'));
        }
        // update global state
        dispatch({
            type: ACCOUNT_UPDATE,
            payload: {
                user: body
            }
        });
        return response;
    };

    const setPassword = async (password) => {
        const response = await axios.post('/users/change/password', {
            newPassword: password,
            currentPassword: ''
        });
        if (response.status !== 204) {
            // there has been an error
            return Promise.reject(new Error('request failed'));
        }
        const serviceToken = axios.defaults.headers.common.Authorization.split('Bearer ')[1];
        // now that password is set, we set the session
        setSession(serviceToken);
        dispatch({
            type: LOGIN,
            payload: {
                user: {}
            }
        });
        return response;
    };

    const resetPassword = (email) => console.log(email);

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider
            value={{ ...state, login, logout, setPassword, confirmUser, updateCustomUserData, resetPassword, updateProfile }}
        >
            {children}
        </JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
