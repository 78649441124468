// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconMail, IconCalculator, IconClipboardList } from '@tabler/icons';

// constant
const icons = {
    IconClipboardList,
    IconCalculator,
    IconMail
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const calculations = {
    id: 'calculations',
    title: <FormattedMessage id="calculations" />,
    type: 'group',
    children: [
        {
            id: '/user/calculations',
            title: <FormattedMessage id="calculations.overview" />,
            type: 'item',
            url: '/user/calculations',
            icon: icons.IconClipboardList,
            breadcrumbs: false
        },
        {
            id: 'new-calculation',
            title: <FormattedMessage id="calculations.new" />,
            type: 'collapse',
            icon: icons.IconCalculator,
            children: [
                {
                    id: '/user/calculations/create/border-crosser',
                    title: <FormattedMessage id="calculations.borderCrosser" />,
                    type: 'item',
                    url: '/user/calculations/create/border-crosser',
                    breadcrumbs: false
                },
                {
                    id: '/user/calculations/create/weekly-resident',
                    title: <FormattedMessage id="calculations.weeklyResident" />,
                    type: 'item',
                    url: '/user/calculations/create/weekly-resident',
                    breadcrumbs: false
                },
                {
                    id: '/user/calculations/create/resident',
                    title: <FormattedMessage id="calculations.resident" />,
                    type: 'item',
                    url: '/user/calculations/create/resident',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: '/user/calculations/request-for-social-contributions',
            title: <FormattedMessage id="calculations.requestForSocialContributions" />,
            type: 'item',
            url: '/user/calculations/request-for-social-contributions',
            icon: icons.IconMail,
            breadcrumbs: false
        }
    ]
};

export default calculations;
