import calculations from './calculations';
import dashboard from './dashboard';
import user from './user';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, calculations, user]
};

export default menuItems;
