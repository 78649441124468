/**
 * axios setup to use mock service
 */

import axios from 'axios';

// project imports
import config from '../config';

const axiosConfig = axios.create({
    baseURL: `${config.apiUrl}/v1/`,
    timeout: 10000,
    headers: { Accept: 'application/json' }
});
// interceptor for http
axiosConfig.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || console.log(error))
);

export default axiosConfig;
