// action - state management
import { ACCOUNT_UPDATE, LOGIN, LOGOUT, REGISTER } from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER: {
            const { user } = action.payload;
            return {
                ...state,
                user
            };
        }
        case LOGIN: {
            const { user } = action.payload;
            return {
                ...state,
                isLoggedIn: true,
                isInitialized: true,
                user
            };
        }
        case LOGOUT: {
            return {
                ...state,
                isInitialized: true,
                isLoggedIn: false,
                user: null
            };
        }
        case ACCOUNT_UPDATE: {
            const { email, phone, logoUrl } = action.payload.user;
            state = {
                ...state,
                user: {
                    ...state.user,
                    customData: {
                        ...state.user.customData,
                        displayEmailAddress: email,
                        displayMobileNumber: phone
                    }
                }
            };
            if (logoUrl) {
                const url = new URL(logoUrl);
                // add a time parameter to url so client re-renders image on state update
                url.searchParams.set('t', new Date().getTime().toString());
                state.user.customData.logoUrl = url.toString();
            }
            return state;
        }
        default: {
            return { ...state };
        }
    }
};

export default accountReducer;
