import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <a href="http://wordpress.p663381.webspaceconfig.de/">
        <Logo />
    </a>
);

export default LogoSection;
