// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconMail, IconCalculator, IconClipboardList } from '@tabler/icons';

// constant
const icons = {
    IconClipboardList,
    IconCalculator,
    IconMail
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const calculations = {
    id: 'calculations',
    title: <FormattedMessage id="calculations" />,
    type: 'group',
    children: [
        {
            id: 'new-calculation',
            title: <FormattedMessage id="calculations.new" />,
            type: 'collapse',
            icon: icons.IconCalculator,
            children: [
                {
                    id: window.location.pathname,
                    title: <FormattedMessage id={window.location.pathname} />,
                    type: 'item',
                    url: window.location.pathname,
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default calculations;
