import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import PublicLayout from 'layout/PublicLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';

// help page
const HelpSection = Loadable(lazy(() => import('views/public/help')));

// public calculations
const CalculationResult = Loadable(lazy(() => import('views/public/calculations/CalculationResult')));
const BorderCrosserCalculationForm = Loadable(lazy(() => import('views/public/calculations/border_crosser/CalculationForm/form')));
const WeeklyResidentCalculationForm = Loadable(lazy(() => import('views/public/calculations/weekly_resident/CalculationForm')));
const ResidentCalculationForm = Loadable(lazy(() => import('views/public/calculations/resident/CalculationForm')));

// ==============================|| AUTH ROUTING ||============================== //

const PublicRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <PublicLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/welcome',
            element: <HelpSection />
        },
        {
            path: '/calculations/border-crosser',
            element: <BorderCrosserCalculationForm borderCrosser={{}} spouse={{}} />
        },
        {
            path: '/calculations/result/border-crosser',
            element: <CalculationResult />
        },
        {
            path: '/calculations/weekly-resident',
            element: <WeeklyResidentCalculationForm />
        },
        {
            path: '/calculations/result/weekly-resident',
            element: <CalculationResult />
        },
        {
            path: '/calculations/resident',
            element: <ResidentCalculationForm />
        },
        {
            path: '/calculations/result/resident',
            element: <CalculationResult />
        }
    ]
};

export default PublicRoutes;
