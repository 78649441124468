// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconUser, IconMail, IconSettings } from '@tabler/icons';

// constant
const icons = {
    IconUser,
    IconMail,
    IconSettings
};

// -----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//

const user = {
    id: 'user',
    title: <FormattedMessage id="user" />,
    type: 'group',
    children: [
        {
            id: '/user/account',
            title: <FormattedMessage id="user.settings" />,
            type: 'item',
            url: '/user/account',
            icon: icons.IconSettings,
            breadcrumbs: false
        }
    ]
};

export default user;
